import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SubCard from "./SubCard";
import SubCardLeadTime from "./SubCardLeadTime";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { useSnackbar } from "notistack";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import FactoryIcon from "@mui/icons-material/Factory";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import MainCard from "./MainCard";
import { playGameRequest } from "../requests/playGameRequest";
import { calculateCosts } from "../algorithms/claculateCosts";
import ShakeComponent from "../utils/ShakeComponent";
import ProgressCard from "./ProgressCard";
import AccordionChart from "./charts/AccordionChart";
import { Card, useMediaQuery } from "@mui/material";

const maxInput = 99;

const Panel = ({ player, game, ableToPlay, cbAbleToPlay }) => {

  const matches = useMediaQuery('(max-width: 800px)')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [order, setOrder] = useState("");

  const [lockOrder, setLockOrder] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (ableToPlay) {
      const snack = enqueueSnackbar("Place order.", {
        variant: "info", //| error | success | warning | info
        autoHideDuration: 4000, //Millisekunder eller null
        style: { cursor: "pointer" },
        SnackbarProps: {
          onClick: () => {
            closeSnackbar(snack); //Stäng vid klick
          },
        },
      });
      sessionStorage.setItem("animationCanTrigger", "true");
      setIsLoading(false);
      setLockOrder(false);
    } else {
      setIsLoading(true);
      setLockOrder(true);
    }
  }, [ableToPlay]);

  const placeOrder = async (order) => {
    const result = await playGameRequest(player.ID, game.Round, order, game.ID);

    if (result.success) {
      cbAbleToPlay(false);
      setLockOrder(true);
      setIsLoading(true);
      setOrder("");
      sessionStorage.setItem("animationCanTrigger", "false");

      const snack = enqueueSnackbar(
        "Your order was placed, wait until next round to continue.",
        {
          variant: "success", //| error | success | warning | info
          autoHideDuration: 4000, //Millisekunder eller null
          //style: { cursor: "pointer", width:'97vw'}, //Om vi vill ha fullwidth
          style: { cursor: "pointer" },
          SnackbarProps: {
            onClick: () => {
              closeSnackbar(snack); //Stäng vid klick
            },
          },
        }
      );
    } else {
      const snack = enqueueSnackbar(result.error, {
        variant: "error", //| error | success | warning | info
        autoHideDuration: 4000, //Millisekunder eller null
        //style: { cursor: "pointer", width:'97vw'}, //Om vi vill ha fullwidth
        style: { cursor: "pointer" },
        SnackbarProps: {
          onClick: () => {
            closeSnackbar(snack); //Stäng vid klick
          },
        },
      });
      console.log(result.error);
    }
  };

  return (
    <>
      <div className="gameWrapper">
        <div className="cardSection">
          {/* <h2>{"Inventory costs: " + calculateCosts(player.Stock, player.Backlog) + "$"}</h2> */}
          <MainCard chartType={"bar"} data={player.IncomingOrder} />
          <SubCard
            chartType="bar"
            data={player.Outgoing}
            subTitle="Leaving next week"
            title="Outgoing transport"
            icon={<LocalShippingIcon fontSize="large" />}
            animationDirection={"left"}
          />
          <ProgressCard
            className="progressCard"
            week={game?.Round + 1 || 0}
            costs={
              (player.Stock?.slice(-1)[0] || 0) * 5 +
              (player.Backlog?.slice(-1)[0] || 0) * 25
            }
            totalCosts={calculateCosts(player?.Stock, player?.Backlog)}
            round={game?.Round}
            game={game}
            player={player}
          />
          <ShakeComponent trigger={player.Backlog?.slice(-1)[0] > 0}>
            <SubCard
              chartType="bar"
              data={player.Stock}
              subTitle={
                player.Backlog?.slice(-1)[0] > 0 ? (
                  "Backlog: " + player.Backlog?.slice(-1)[0]
                ) : (
                  <br />
                )
              }
              title="Stock"
              icon={<WarehouseIcon fontSize="large" />}
              animationDirection={"down"}
              dontAnimateIcon={true}
              isBacklog={true}
            />
          </ShakeComponent>
          <Card className="orderInputCard" variant="outlined">
            <TextField
              // !!!!! deaktivera vid GodMode !!!!!!
              disabled={
                lockOrder || player.ID !== localStorage.getItem("userId")
              }
              className="placeOrderInput"
              type="number"
              sx={{ marginRight: "8px" }}
              id="outlined-number"
              value={order}
              focused={!lockOrder}
              onChange={(e) => {
                var value = parseInt(e.target.value, 10);

                if (value > maxInput) value = maxInput;

                setOrder(value);
              }}
              inputRef={(input) => input && input.focus()}
              label="New order"
              variant="outlined"
              inputProps={{ maxInput }}
              fullWidth
            />
            <LoadingButton
              className="placeOrderButton"
              // sx={{marginBottom: "1em"}}
              loading={isLoading}
              loadingPosition="start"
              startIcon={<AddBoxIcon />}
              variant="outlined"
              // fullWidth
              onClick={() => placeOrder(order)}
              disabled={
                lockOrder ||
                order === "" ||
                order === null ||
                order === undefined
              }
              sx={matches ? {fontSize: "10px"} : {}}
            >
              <span>{ableToPlay ? "Place order" : "waiting for plays"}</span>
            </LoadingButton>
          </Card>
          {player.Role === "Manufacturer" ? (
            <SubCardLeadTime
              icon={<FactoryIcon fontSize="large" />}
              production1Display={player.Production?.slice(-1)[0]}
              production2Display={player.Incoming?.slice(-1)[0]}
              production1={player.Production}
              production2={player.Incoming}
              subTitle="Leaving next week"
              title="Outgoing"
            />
          ) : (
            <SubCard
              chartType="bar"
              data={player.Incoming}
              subTitle="In stock next week"
              title="Incoming delivery"
              icon={
                <LocalShippingIcon
                  fontSize="large"
                  style={{ transform: "scaleX(-1)" }}
                />
              }
              animationDirection={"right"}
            />
          )}
        </div>
      </div>
      <Accordion 
  variant="outlined"
  style={{ borderRadius: '5px' }}
  sx={{
    '&:before': { // Remove the default expansion divider line
      display: 'none',
    },
    '&.Mui-expanded': { // Keep corners rounded when expanded
      borderRadius: '5px',
    },
  }}
>
  <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    aria-controls="panel1a-content"
    id="panel1a-header"
    sx={{
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      '&.Mui-expanded': { // Keep top corners rounded when expanded
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
      },
    }}
  >
    <div>Statistics - {player.Role}</div>
  </AccordionSummary>
  <AccordionDetails>
    <AccordionChart
      stock={player?.Stock || [0]}
      backlog={player?.Backlog || [0]}
      placedOrder={player?.Play || [0]}
      incoming={player?.Incoming || [0]}
      weeks={game?.Rounds || 0}
      playerRole={player.Role}
    />
  </AccordionDetails>
</Accordion>
    </>
  );
};

export default Panel;
