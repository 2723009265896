import React from 'react';
import Card from '@mui/material/Card';
import { TextField } from '@mui/material';
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import maLogo from "../assets/ma_logo.webp";
import Alert from '@mui/material/Alert';
import Turnstile, { useTurnstile } from "react-turnstile";
import { LoadingButton } from '@mui/lab';
import Footer from './Footer';


const Billing = () => {
    const navigate = useNavigate();

    const [players, setPlayers] = useState(4);
    const [price, setPrice] = useState(105);

    const [contactPerson, setContactPerson] = useState("");
    const [contactEmailAddress, setContactEmailAddress] = useState("");

    const [companyName, setCompanyName] = useState("");
    const [invoiceEmailAddress, setInvoiceEmailAddress] = useState("");

    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [addressLine3, setAddressLine3] = useState("");
    const [country, setCountry] = useState("");

    const [vatNumber, setVatNumber] = useState("");
    const [addBillingInfo, setAddBillingInfo] = useState("");


    const [playersError, setPlayersError] = useState("");

    const [contactPersonError, setContactPersonError] = useState("");
    const [contactEmailAddressError, setContactEmailAddressError] = useState("");


    const [companyNameError, setCompanyNameError] = useState("");
    const [invoiceEmailAddressError, setInvoiceEmailAddressError] = useState("");

    const [addressLine1Error, setAddressLine1Error] = useState("");
    const [addressLine2Error, setAddressLine2Error] = useState("");
    const [addressLine3Error, setAddressLine3Error] = useState("");
    const [countryError, setCountryError] = useState("");

    const [vatNumberError, setVatNumberError] = useState("");
    const [addBillingInfoError, setAddBillingInfoError] = useState("");

    const [sentSuccessFully, setSentSucessFully] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorText, setErrorText] = useState("")

    const [showCaptcha, setShowCaptcha] = useState(false);

    const turnstile = useTurnstile();

    function calculatePrice(players) {
        let p = players * 20;
        if (p < 100) {
            p += 25
        }

        return p;
    }

    function handlePlayersUpdate(players) {
        let pls = Number(players);
        setPlayers(pls);
        setPrice(calculatePrice(pls));
    }

    async function sendForm(request) {
        setShowError(false);

        try {

            let response = await fetch('https://beergameapi-b7cb49ccfea5.herokuapp.com/order', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request),
            });

            if (response.ok) {
                
                setSentSucessFully(true);
            } else {
                if (response.status == 400) {
                    setErrorText("Something went wrong.");
                    setShowError(true);
                            
                }

                if (response.status == 422) {
                }

                if (response.status == 401) {
                    setErrorText("Captcha challenge unsuccessful. Please verify that you're not a robot.")
                    setShowError(true);
                    window.scrollTo(0, 0)
                    return;
                }

                let responseData = await response.json();
                let error = responseData.error;
                window.scrollTo(0, 0)
                markFailedValidation(error);
            }
        } catch (error) {
            setErrorText("Something went wrong.");
            setShowError(true);
        }

        finally {
            setIsLoading(false);
            setShowCaptcha(false);
        }
    }

    function markFailedValidation(error) {
        if (error.nbrOfPlayers) {
            setPlayersError(error.nbrOfPlayers);
        }

        if (error.contactPerson) {
            setContactPersonError(error.contactPerson);
        }

        if (error.contactEmailAddress) {
            setContactEmailAddressError(error.contactEmailAddress);
        }

        if (error.companyName) {
            setCompanyNameError(error.companyName);
        }

        if (error.invoiceEmailAddress) {
            setInvoiceEmailAddressError(error.invoiceEmailAddress);
        }

        if (error.adressLine1) {
            setAddressLine1Error(error.addressLine1);
        }

        if (error.addressLine2) {
            setAddressLine2Error(error.addressLine2);
        }

        if (error.addressLine3) {
            setAddressLine3Error(error.addressLine3);
        }

        if (error.country) {
            setCountryError(error.country);
        }

        if (error.vatNumber) {
            setVatNumberError(error.vatNumber);
        }

        if (error.addBillingInfo) {
            setAddBillingInfoError(error.addBillingInfo)
        }
    }

    const close = () => {
        navigate('/');
    };

    function TurnstileWidget() {

        return (
          <Turnstile
            style={{marginTop:"2em"}}

            sitekey="0x4AAAAAAAVR8ZxDWu20rVUS"
            onVerify={(token) => {
                if (isLoading === false) {
                    return;
                }
                
                    let reqObj = {
                        nbrOfPlayers: players,
            
                        contactPerson,
                        contactEmailAddress: contactEmailAddress,
            
                        companyName,
                        invoiceEmailAddress: invoiceEmailAddress,
            
                        addressLine1,
                        addressLine2,
                        addressLine3,
                        country,
            
                        vatNumber,
                        addBillingInfo: addBillingInfo,
    
                        token: token
                    };
    
                    sendForm(reqObj);
                }         
            }
          />
        );
      }

    

    return (
        <div>
            <div style={{ margin: '0 auto', maxWidth: "35em", marginTop: "2em", marginBottom: "2em" }}>
                <Card style={{ padding: "1em" }} variant="outlined">
                    <Grid container>
                        <Grid item xs={9}>
                            <h1>Billing details</h1>
                        </Grid>
                        <Grid item xs={3}>
                            <img src={maLogo} alt="My Logo" style={{ width: '80px', height: 'auto' }} />
                        </Grid>
                    </Grid>

                    <p>For commercial organizations we charge USD $20 per game participant. For invoices with a sum less than $100, an extra administrative fee of $25 is charged.</p>

                    <p>Thank you for being honest and helping us cover our costs for running the Beer game!</p>

                    {showError && <Alert variant="outlined" severity="error" style={{marginTop: "1em"}}>
                        {errorText}
                    </Alert>}

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                label="Players"
                                fullWidth={true}
                                value={players || ""}
                                onChange={(event) => { handlePlayersUpdate(event.target.value); setPlayersError("") }}
                                style={{ marginTop: "1em" }}
                                inputProps={{ type: 'number', min: '1' }}
                                required
                                error={!!playersError}
                                helperText={playersError}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                fullWidth={true}
                                value={price}
                                style={{ marginTop: "1em" }}
                                disabled={true}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    ),
                                }}
                            >
                            </TextField>
                        </Grid>
                    </Grid>

                    <p style={{ marginTop: "2em", marginLeft: "0.1em", marginBottom: "0.1em", fontSize: "large" }}>Contact</p>
                    <TextField
                        variant="outlined"
                        label="Name"
                        fullWidth={true}
                        value={contactPerson}
                        onChange={(event) => { setContactPerson(event.target.value); setContactPersonError("") }}
                        inputProps={{ maxLength: 50 }}
                        required
                        error={!!contactPersonError}
                        helperText={contactPersonError}
                    >

                    </TextField>

                    <TextField
                        variant="outlined"
                        label="Email"
                        fullWidth={true}
                        value={contactEmailAddress}
                        onChange={(event) => { setContactEmailAddress(event.target.value); setContactEmailAddressError("") }}
                        style={{ marginTop: "1em" }}
                        inputProps={{ maxLength: 50 }}
                        required
                        error={!!contactEmailAddressError}
                        helperText={contactEmailAddressError}
                    >
                    </TextField>

                    <p style={{ marginTop: "2em", marginLeft: "0.1em", marginBottom: "0.1em", fontSize: "large" }}>Invoice details</p>
                    <TextField
                        variant="outlined"
                        label="Company Name"
                        fullWidth={true}
                        value={companyName || ""}
                        onChange={(event) => { setCompanyName(event.target.value); setCompanyNameError("") }}
                        inputProps={{ maxLength: 50 }}
                        required
                        error={!!companyNameError}
                        helperText={companyNameError}
                    >
                    </TextField>

                    <TextField
                        variant="outlined"
                        label="Invoice email"
                        fullWidth={true}
                        value={invoiceEmailAddress}
                        onChange={(event) => { setInvoiceEmailAddress(event.target.value); setInvoiceEmailAddressError("") }}
                        style={{ marginTop: "1em" }}
                        inputProps={{ maxLength: 50 }}
                        required
                        error={!!invoiceEmailAddressError}
                        helperText={invoiceEmailAddressError}
                    >
                    </TextField>

                    <TextField
                        variant="outlined"
                        label="Billing address line 1"
                        fullWidth={true}
                        value={addressLine1}
                        onChange={(event) => { setAddressLine1(event.target.value); setAddressLine1Error("") }}
                        style={{ marginTop: "1em" }}
                        inputProps={{ maxLength: 50 }}
                        required
                        error={!!addressLine1Error}
                        helperText={addressLine1Error}
                    >

                    </TextField>

                    <TextField
                        variant="outlined"
                        label="Billing address line 2"
                        fullWidth={true}
                        value={addressLine2}
                        onChange={(event) => { setAddressLine2(event.target.value); setAddressLine2Error }}
                        style={{ marginTop: "1em" }}
                        inputProps={{ maxLength: 50 }}
                        required
                        error={!!addressLine2Error}
                        helperText={addressLine2Error}
                    ></TextField>

                    <TextField
                        variant="outlined"
                        label="Billing address line 3"
                        fullWidth={true}
                        value={addressLine3}
                        onChange={(event) => { setAddressLine3(event.target.value); setAddressLine3Error("") }}
                        style={{ marginTop: "1em" }}
                        inputProps={{ maxLength: 50 }}
                        required
                        error={!!addressLine3Error}
                        helperText={addressLine3Error}
                    ></TextField>

                    <TextField
                        variant="outlined"
                        label="Country"
                        fullWidth={true}
                        value={country}
                        onChange={(event) => { setCountry(event.target.value); setCountryError("") }}
                        style={{ marginTop: "1em" }}
                        inputProps={{ maxLength: 50 }}
                        required
                        error={!!countryError}
                        helperText={countryError}
                    ></TextField>

                    <TextField
                        variant="outlined"
                        label="VAT Number"
                        fullWidth={true}
                        value={vatNumber}
                        onChange={(event) => { setVatNumber(event.target.value); setVatNumberError("") }}
                        style={{ marginTop: "1em" }}
                        inputProps={{ maxLength: 25 }}
                        error={!!vatNumberError}
                        helperText={vatNumberError}
                    >
                    </TextField>

                    <TextField
                        variant="outlined"
                        label="Additional billing info"
                        fullWidth={true}
                        value={addBillingInfo}
                        onChange={(event) => { setAddBillingInfo(event.target.value); setAddBillingInfoError("") }}
                        style={{ marginTop: "1em" }}
                        inputProps={{ maxLength: 250 }}
                        error={!!addBillingInfoError}
                        helperText={addBillingInfoError}
                        multiline
                        rows={4}
                    >
                    </TextField>

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {showCaptcha && <TurnstileWidget />}
                    </div>

                    <div style={{
                        marginTop: "2em", display: 'flex',
                        justifyContent: 'flex-end'
                    }}>

                        <Button
                            variant="outlined"
                            onClick={close}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            loading={isLoading}
                            variant="outlined"
                            style={{ marginLeft: "1em" }}
                            onClick={() => {setIsLoading(true); setShowCaptcha(true)}}
                            disabled={contactPerson == "" || contactEmailAddress == "" || companyName == "" || invoiceEmailAddress == "" || addressLine1 == "" || addressLine2 == "" || addressLine3 == "" || country == ""}
                        >
                            Send
                        </LoadingButton>
                    </div>
                </Card>

                {/* Success Dialog */}
                <Dialog open={sentSuccessFully} onClose={close}>
                    <DialogTitle>Invoice Request Received</DialogTitle>
                    <DialogContent>
                        <p>
                            Thanks! We are processing your request. Your invoice will be sent to your invoice email address within one work week.
                        </p>
                        <p>
                            Meanwhile feel free to enjoy the Beer game.
                        </p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={close} variant="outlined">OK</Button>
                    </DialogActions>
                </Dialog>
            </div>
            <Footer />
        </div>
    );
};

export default Billing;