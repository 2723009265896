import React, { useEffect, useState } from "react";
import { statusGameRequest } from "../requests/statusGameRequest";
import { useSnackbar } from "notistack";
import SummaryCard from "../components/SummaryCard";

import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { isMobile } from "react-device-detect";


export const GameOver = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const player = {
    userId: localStorage.getItem("userId"),
    gameId: localStorage.getItem("gameId"),
    role: localStorage.getItem("role"),
  };

  const [rounds, setRounds] = useState(0);
  const [retailer, setRetailer] = useState({});
  const [wholesaler, setWholesaler] = useState({});
  const [distributor, setDistributor] = useState({});
  const [manufacturer, setManufacturer] = useState({});
  const [gameRounds, setGameRounds] = useState(0);


  const getGameResult = async () => {
    try {
      const result = await statusGameRequest(player.userId, player.gameId);

      if (result.success) {
        setRounds(result.response?.status?.Game?.Rounds || 0);
        setRetailer(result.response?.status?.Game?.Players?.Retailer || {});
        setWholesaler(result.response?.status?.Game?.Players?.Wholesaler || {});
        setDistributor(result.response?.status?.Game?.Players?.Distributor || {});
        setManufacturer(result.response?.status?.Game?.Players?.Manufacturer || {});
        setGameRounds(result.response?.status?.Game?.Rounds || 0);
      } else {
        const snack = enqueueSnackbar(result.error, {
          variant: "error", //| error | success | warning | info
          autoHideDuration: 6000, //Millisekunder eller null
          //style: { cursor: "pointer", width:'97vw'}, //Om vi vill ha fullwidth
          style: { cursor: "pointer" },
          SnackbarProps: {
            onClick: () => {
              closeSnackbar(snack); //Stäng vid klick
            },
          },
        });
      }
    } catch (error) {
      console.error("error :", error);
      const snack = enqueueSnackbar(error, {
        variant: "error", //| error | success | warning | info
        autoHideDuration: 6000, //Millisekunder eller null
        //style: { cursor: "pointer", width:'97vw'}, //Om vi vill ha fullwidth
        style: { cursor: "pointer" },
        SnackbarProps: {
          onClick: () => {
            closeSnackbar(snack); //Stäng vid klick
          },
        },
      });
    }
  };

  useEffect(() => {
    getGameResult();
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const plotWidth = Math.max(300, windowWidth - 100) || 0;

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const costColor = (costs) => {
    if (costs <= 75) {
      return "#43a047";
    } else if (costs >= 125) {
      return "#d32f2f";
    } else {
      return "#ff9800";
    }
  };

  const setAccCostsData = (stockArray, backlogArray) => {
    if (!stockArray || !backlogArray) {
      return [0, 0];
    } else {
      const calculateAccCosts = (array, costsPerUnit) =>
        array.map(
          (val, i) =>
            array.slice(0, i + 1).reduce((acc, curr) => acc + curr, 0) *
            costsPerUnit
        );

      const stockCosts = calculateAccCosts(stockArray, 5);
      const backlogCosts = calculateAccCosts(backlogArray, 25);


      const maxLength = gameRounds;

      // Summera motsvarande värden, hantera fall där en array är kortare
      const combinedCosts = Array.from({ length: maxLength }).map((_, i) => {
        const stockValue = i < stockCosts.length ? stockCosts[i] : 0;
        const backlogValue = i < backlogCosts.length ? backlogCosts[i] : 0;
        return stockValue + backlogValue;
      });

      return combinedCosts.map((yValue, index) => ({
        x: index + 1,
        y: yValue,
        color: costColor(yValue / (index + 1)),
      }));
    }
  };

  const setProductionChainTotalData = (
    retailerData,
    wholesalerData,
    distributorData,
    manufacturerData
  ) => {
    // Hitta längden på den längsta arrayen
    const maxLength = Math.max(
      retailerData.length,
      wholesalerData.length,
      distributorData.length,
      manufacturerData.length
    );

    // Skapa en ny array för att lagra resultatet
    const totalData = [];

    for (let i = 0; i < maxLength; i++) {
      // Hämta y-värden för varje index i de olika arrayerna, använd 0 om värdet saknas
      const retailerY = retailerData[i] ? retailerData[i].y : 0;
      const wholesalerY = wholesalerData[i] ? wholesalerData[i].y : 0;
      const distributorY = distributorData[i] ? distributorData[i].y : 0;
      const manufacturerY = manufacturerData[i] ? manufacturerData[i].y : 0;

      // Summera y-värdena
      const totalY = retailerY + wholesalerY + distributorY + manufacturerY;

      // Lägg till det summerade värdet i resultatarrayen
      totalData.push({
        week: i + 1,
        cost: totalY,
        color: costColor((totalY / 4) / (i + 1))
      });
    }

    return totalData;
  };

  const retailerCosts = setAccCostsData(retailer.Stock, retailer.Backlog);
  const wholesalerCosts = setAccCostsData(wholesaler.Stock, wholesaler.Backlog);
  const distributorCosts = setAccCostsData(distributor.Stock, distributor.Backlog);
  const manufacturerCosts = setAccCostsData(manufacturer.Stock, manufacturer.Backlog);

  const productionChainTotal = setProductionChainTotalData(retailerCosts, wholesalerCosts, distributorCosts, manufacturerCosts);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: 60,
      }}
    >
      <div className="roleSummaryGrid">
        <h1 style={{ marginTop: 20, marginBottom: 40 }}>Summary</h1>
        <div style={{ maxWidth: 1400, margin: "0 auto", width: isMobile? "20em" : "40em" }}>
          <div className="summaryCard">
            <Accordion variant="outlined" defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div>Total supply chain cost</div>
              </AccordionSummary>
              <AccordionDetails>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={productionChainTotal} margin={{ top: 10, right: 20, left: 20, bottom: 20 }}>
                    <XAxis dataKey="week" label={{ value: "Week", position: "insideBottom", offset: -10 }} />
                    <YAxis label={{ value: "Total cost", angle: -90, position: "left", offset: 10 }} />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="cost"
                      stroke="blue"
                      strokeWidth={2}
                      dot={(props) => {
                        const { cx, cy, payload } = props;
                        return (
                          <circle
                            key={payload.week}
                            cx={cx}
                            cy={cy}
                            r={5}
                            fill={payload.color} // Use the color property from the data point
                            stroke="none"
                          />
                        );
                      }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>

        <SummaryCard player={retailer} weeks={rounds} />
        <SummaryCard player={wholesaler} weeks={rounds} />
        <SummaryCard player={distributor} weeks={rounds} />
        <SummaryCard player={manufacturer} weeks={rounds} />
      </div>
    </div>
  );
};
