import React from 'react';
import { Card, Grid, Box, Button } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { useNavigate } from 'react-router-dom';
import maLogo from "../assets/ma_logo.webp";
import Footer from './Footer';

const SelectPaymentMethod = () => {
  const navigate = useNavigate();

  const handleCardPayment = () => {
    navigate('/checkout');
  };

  const handleInvoicePayment = () => {
    navigate('/billing');
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    }}>

      <Box sx={{ flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ margin: '0 auto', maxWidth: "35em", marginTop: "2em", marginBottom: "2em" }}>
          <Card style={{ padding: "1em" }} variant="outlined">
            <Grid container>
              <Grid item xs={9}>
                <h1>Select payment method</h1>
              </Grid>
              <Grid item xs={3}>
                <img src={maLogo} alt="My Logo" style={{ width: '80px', height: 'auto' }} />
              </Grid>
            </Grid>

            <p>For commercial organizations we charge USD $20 per game participant. For invoices with a sum less than $100, an extra administrative fee of $25 is charged.</p>
            <p>Thank you for being honest and helping us cover our costs for running the Beer game!</p>

            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 2,
              marginTop: 3,
            }}>
              <Button
                variant="outlined"
                onClick={handleCardPayment}
                startIcon={<CreditCardIcon />}
              >
                Pay with card
              </Button>

              <Button
                variant="outlined"
                onClick={handleInvoicePayment}
                startIcon={<ReceiptLongIcon />}
              >
                Pay with invoice
              </Button>
            </Box>
          </Card>
        </div>
      </Box>
      <Footer />
    </Box>
  );
};

export default SelectPaymentMethod;
