import { Grid, Box, Typography, Divider, Link } from '@mui/material';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#f5f5f5',
        padding: '3em 2em',
        borderTop: '1px solid #ddd',
      }}
    >
      <Box sx={{ maxWidth: '40em', margin: 'auto' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Contact Information
            </Typography>
            <Typography variant="body1">MA-system AB</Typography>
            <Typography variant="body1">Porfyrvägen 14</Typography>
            <Typography variant="body1">SE-224 78 LUND</Typography>
            <Typography variant="body1">Sweden</Typography>
            <Typography variant="body1">
              <Link href="mailto:beergame@masystem.com" underline="hover" color="inherit">
                beergame@masystem.com
              </Link>
            </Typography>
            <Typography variant="body1">
              <Link href="https://www.masystem.se/home" underline="hover" color="inherit">
                masystem.se
              </Link>
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              About MA-system
            </Typography>
            <Typography variant="body2" color="text.secondary">
              The MA-system company group consists of MA-system Consulting and MA-system Utbildning.
              MA-system offers services and products ranging from strategic profitability analysis and
              Supply Chain Control systems to operational improvements of processes and education of staff.
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ marginY: '2em' }} />
        <Typography variant="caption" display="block" textAlign="center" color="text.secondary">
          © {new Date().getFullYear()} MA-system Consulting AB. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;